<template>
<!-- 充值中心 -->
  <div class="voucherCenter">
      <div class="voucherCenter-inner">
          <div class="voucher-user">
              <h2>充值账号：{{msg.name}}</h2>
          </div>
          <form action="" id="vocucher">
            <div class="voucher-money">
                <h3>充值金额(￥)<span>(请选择~)</span> </h3>
                <div class="voucher-money-cont">
                        <div>
                            <el-radio v-model="money" name="money" label="100" @change="moneyNum(money)" border>100</el-radio>
                            <el-radio v-model="money" name="money" label="300" @change="moneyNum(money)" border>300</el-radio>
                            <el-radio v-model="money" name="money" label="500" @change="moneyNum(money)" border>500</el-radio>
                            <el-radio v-model="money" name="money" label="1000" @change="moneyNum(money)" border>1000</el-radio>
                            <el-radio v-model="money" name="money" label="2000" @change="moneyNum(money)" border>2000</el-radio>
                            <el-radio v-model="money" name="money" label="0" @change="moneyNum(money)" border>
                                其他金额
                                <!-- <input type="number" min="1" max="100000" stop="1" v-model="othermoney" @input="this.value=this.value.replace(/[^0-9]/gi,'') " onafterpaste="this.value=this.value.replace(/\D/gi,'')"> -->
                                <input type="text" v-show="ortherShow" v-model="othermoney" @input='ortherNum(othermoney)' class="otherMoney" placeholder="请输入金额">
                            </el-radio>
                        </div>
                        <div class="voucher-detail">
                            <el-row>
                                <el-col :span="8">
                                    <div>
                                        <label for="">充值账号：</label>
                                        <span>{{msg.name}}</span>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div>
                                        <label for="">充值金额：</label>
                                        <span>{{money}}</span>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <div>
                                        <label for="">收款名称：</label>
                                        <span>上海恒链科技有限公司</span>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        <label for="">付款公司：</label>
                                        <span>{{msg.cid_name}}</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="voucher-act">
                            <label for="">实付金额：</label>
                            <span>￥{{money}}</span>
                        </div>
                        <div class="zfStyle">
                            <h3>请选择付款方式：</h3>
                            <el-radio v-model="pay_type" label="1" border>
                                <img src="../../assets/zfb.png" alt="">
                            </el-radio>
                            <el-radio v-model="pay_type" label="2" border>
                                <img src="../../assets/wx.png" alt="">
                            </el-radio>
                        </div>
                        <div class="tl">
                            <el-checkbox v-model="checked">
                                我已同意 <span class="xieyi" @click="centerDialogVisible = true">《哒哒通充值协议》</span> 
                            </el-checkbox>
                            <el-dialog
                                title="《哒哒通充值协议》"
                                :visible.sync="centerDialogVisible"
                                width="30%"
                                center>
                                <span>
                                    <p>
                                        欢迎使用哒哒通！
                                    </p>
                                    <p class="msgspan">
                                        为使用哒哒通的服务，您应当阅读并遵守《哒哒通充值协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容。
                                    </p>
                                    <p class="msgspan">
                                         除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用哒哒通服务。您使用哒哒通服务，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议。本协议即在您与哒哒通之间产生法律效力，成为对双方均具有约束力的法律文件。
                                    </p>
                                    <p class="msgspan">
                                        哒哒通开发了一套系统，于平台内使用。您可使用现金通过在线支付为您的账号充值金额。充值金额可用于使用哒哒通提供的数据服务。一旦成功充值，将不得回兑成现金或其他形式予以退还，除非我方另行明确同意或者适用的法律法规另有明确要求。您确认并同意充值金额仅可用来支付哒哒通提供的数据服务，且已充入账号的金额不得与其他账号进行交易。如果您违反本条规定进行交易，哒哒通有权采取处理措施。您确认并同意哒哒通有权根据业务需要，独立决定开发、激活或停用金额可适用的某些功能和服务。一旦服务使用完成，您将不得撤销或要求将已提供的服务回兑成相应的现金。
                                    </p>
                                </span>
                                
                                <span slot="footer" class="dialog-footer">
                                    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
                                </span>
                            </el-dialog>
                        </div>
                        <div class="sureV">
                            <button type="button" @click="Payment()" >确认充值</button>
                        </div>
                </div>
            </div>
          </form>
      </div>
      <div class="wxPopup" v-show="wxPopup">
          <div class="wxPopupInner">
              <div class="wxPopupTit">
                  <h2>微信支付<i class="el-icon-close" @click="closeWxPopup"></i></h2>
              </div>
              <div class="wxPopupCont">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="wxPopItemLeft">
                            <img src="../../assets/wxPay.png" alt="">
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="wxPopItemRight">
                            <div class="wxPopupImg">
                                <img :src="wxQR" alt="">
                            </div>
                            <p>请使用 <span>微信</span> 扫一扫二维码完成支付</p>
                            <div class="wxPopupBtn">
                                <el-button type="warning" @click="completed('1')">已支付完成</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
              </div>
              
          </div>
      </div>
  </div>
</template>

<script>
    import VoucherCenter from './VoucherCenter.js';
    export default VoucherCenter;
</script>

<style scoped>
    @import './VoucherCenter.css';
</style>