import VoucherCenter from './VoucherCenter.vue'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
    components:{
        VoucherCenter
    },
    data(){
        return {
            checked:false,
            msg:{
                name:'',
                cid_name:''
            },
            money:'100',
            othermoney:'',
            ortherShow:false,
            pay_type:'1',
            centerDialogVisible:false,
            wxQR: '',
            wxPopup: false,
            out_trade_no: '',
            timer:'',
        }
    },
    created(){
        this.msg = jwt_decode(localStorage.getItem('DDTtoken'))
    },
    methods:{
        moneyNum(val){
            if(val == '0'){
                this.ortherShow = true
            }else {
                this.ortherShow = false
            }
            this.othermoney = ''
        },
        ortherNum(val){
            let num = '' + val
            num = num
                .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
                .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.')
                // eslint-disable-next-line no-useless-escape
                .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
            if (num.indexOf('.') < 0 && num !== '') {
                // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                num = parseFloat(num)
            }
            val = num
            this.othermoney = val
            this.money = val
        },
         // 确认充值
         Payment(){
             if (this.money && this.money != 0) {
                 if (this.checked) {
                     if (this.pay_type == "1") {
                        var fd = new FormData()
                        fd.append('total_amount',this.money)
                        fd.append('subject',this.msg.cid_name + '充值')
                        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                        axios.post(this.GLOBAL.BASE_URL2 + "/pay/AliPay",fd)
                        .then(res=>{
                            if(res.status == 200){
                                window.open(res.data)
                            }
                        })
                        .catch(error=>{
                            console.log(error);
                        })
                     } else if (this.pay_type == "2") {
                        var fdwx = new FormData()
                        fdwx.append('total_amount',this.money)
                        fdwx.append('description',this.msg.cid_name + '充值')
                        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                        axios.post(this.GLOBAL.BASE_URL2 + "/wechat_pay/Pay",fdwx)
                        .then(res=>{
                            if (res.data.status == "1") {
                                this.wxQR = res.data.img
                                this.out_trade_no = res.data.out_trade_no
                                this.wxPopup = true
                                this.timer = setInterval(() => {
                                    this.completed("2")
                                }, 2000);
                                setTimeout(()=>{
                                    clearInterval(this.timer)
                                    if(this.wxPopup == true){
                                        this.$message({
                                            type: 'error',
                                            message: '支付失败',
                                            showClose: true,
                                            center: true
                                        });
                                        this.wxPopup = false
                                    }
                                },2 * 60 * 1000)
                            }
                        })
                        .catch(error=>{
                            console.log(error);
                        })
                    }
                    
                }else {
                    this.$message({
                        type: 'warning',
                        message: '请同意充值协议',
                        showClose: true,
                        center: true
                    });
                }
            }else {
                this.$message({
                    type: 'warning',
                    message: '请选择或输入充值金额',
                    showClose: true,
                    center: true
                });
            }
            
        },
        //  关闭微信支付弹框
        closeWxPopup() {
            this.wxPopup = false
            clearInterval(this.timer)
        },
        // 已完成支付
        completed(val) {
            axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL2 + "/wechat_pay/Query_pay", {
                params: {
                    out_trade_no:this.out_trade_no
                }
            })
            .then(res => {
                if (res.data.status == "1") {
                    clearInterval(this.timer)
                    this.$message({
                        type: 'success',
                        message: '支付成功',
                        showClose: true,
                        center: true
                    });
                    this.closeWxPopup()
                } else if(val == '1') {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error => {
                console.log(error);
            })
        },
    }
}